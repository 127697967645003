import React from 'react';
import AppContext from './AppContext';
import { Toast, ToastContainer } from 'react-bootstrap';

class BottomMenu extends React.Component {
    static contextType = AppContext;
    render() {
        return <div
        aria-live="polite"
        aria-atomic="true"
        className="fixed-bottom"
        style={{ minHeight: '240px', pointerEvents: 'none', zIndex: 99999 }}
    >
        <ToastContainer position="bottom-end" className="p-3 mb-5">
            {this.context.toasts.map((toast, key) =>
                <Toast key={key} onClose={() => this.context.hideToast(toast)} show={toast.show} delay={10000} autohide>
                    <Toast.Header closeButton>
                        <div className='me-auto'><b>{toast.header}</b></div>
                    </Toast.Header>
                    <Toast.Body>{toast.body}</Toast.Body>
                </Toast>
            )}
        </ToastContainer>
    </div>
    }
}

export default BottomMenu;
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

class LoadingPage extends React.Component {
    render(){
        return <h4 className="w-100 h-100 text-white d-flex flex-column align-items-center justify-content-center">
            <FontAwesomeIcon spin icon={faSpinner} />
            Carregando...
        </h4>
    }
}

export default LoadingPage;
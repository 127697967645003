import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import AppContext from './AppContext';

class AppModal extends Component {

    static contextType = AppContext;

    render() {
        return this.context.modal && (
            <Modal show={this.context.showModal} onHide={() => this.context.closeModal()} {...this.context.modal.props}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.context.modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.context.modal.body}</Modal.Body>
                {this.context.modal.footer && <Modal.Footer>{this.context.modal.footer}</Modal.Footer>}
            </Modal>
        );
    }
}

export default AppModal;